// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bestellen-danke-js": () => import("./../../../src/pages/bestellen/danke.js" /* webpackChunkName: "component---src-pages-bestellen-danke-js" */),
  "component---src-pages-bestellen-index-js": () => import("./../../../src/pages/bestellen/index.js" /* webpackChunkName: "component---src-pages-bestellen-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-old-index-js": () => import("./../../../src/pages/old_index.js" /* webpackChunkName: "component---src-pages-old-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wissen-performance-js": () => import("./../../../src/pages/wissen/performance.js" /* webpackChunkName: "component---src-pages-wissen-performance-js" */)
}

